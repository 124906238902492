import { Location } from 'history';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router-dom';

import { PATHS } from 'src/appConfig/paths';
import Navbar from 'src/components/Navbar';
import { IRootState } from 'src/redux/rootReducer';
import { Navigator, TenantService } from 'src/services';
import Dev from './Dev';

import AuthContainer from './StartupContainers/AuthContainer';
import DialogContainer from './StartupContainers/DialogContainer';
import LightboxContainer from './StartupContainers/LightboxContainer';
import NotFound from './StartupContainers/NotFound';
import SplashScreen from './StartupContainers/SplashScreen';
import ToastContainer from './StartupContainers/ToastContainer';

import { Box } from '@mui/material';
import CustomErrorBoundary from 'src/components/ErrorBoundary/CustomErrorBoundary';
import Footer from 'src/components/Footer';
import { useComponentDidMount } from 'src/hooks';
import ContentContainer from './StartupContainers/ContentContainer';
import LoadingContainer from './StartupContainers/LoadingContainer';
import NetworkStatusContainer from './StartupContainers/NetworkStatusContainer';
import ScrollToTop from './StartupContainers/ScrollToTop';
import CheckPasswordExpiredContainer from './UAMContainer/ChangePasswordExpired/container';
import MiscellaneousSearchTable from './SearchAllDocumentListing';

const Dashboard = React.lazy(() => import('./Dashboard'));
const UsersManagement = React.lazy(() => import('./UsersManagement'));
const CRUUSerContainer = React.lazy(() => import('./CRUUSerContainer'));
const SwitchUser = React.lazy(() => import('./SwitchUser'));
const GlobalSettings = React.lazy(() => import('./GlobalSettings'));
const StaffListing = React.lazy(() => import('./StaffListing'));
const CheckPrinting = React.lazy(() => import('./CheckPrinting'));
const CheckPrintingSuccess = React.lazy(() => import('./CheckPrinting/CheckPrintingSuccess'));
const CheckRegisterListing = React.lazy(() => import('./CheckRegisterListing'));
const CheckRegisterDetail = React.lazy(() => import('./CheckRegisterContainer'));
const EditProfile = React.lazy(() => import('./EditProfile'));
const ChangePasswordExpiredEmptyScreen = React.lazy(
  () => import('./UAMContainer/ChangePasswordExpired/emptyScreen')
);
const PurchaseOrder = React.lazy(() => import('./PurchaseOrderContainer/PO'));
const AdditionalPOFormsContainer = React.lazy(() => import('./AdditionalPOForms'));
const VendorsManagement = React.lazy(() => import('./VendorsManagement'));
const EditVendorsMaster = React.lazy(() => import('./Vendors/VendorMaster'));
const VendorRegistration = React.lazy(() => import('./Vendors/VendorRegistration'));
const SubmittedPurchaseOrder = React.lazy(() => import('./PurchaseOrderContainer/PO/SubmittedPO'));
const POListing = React.lazy(() => import('./POListing'));
const SelectChangeFormType = React.lazy(
  () => import('./PurchaseOrderContainer/POChange/SelectChangeFormType')
);
const POChangeForm = React.lazy(() => import('./PurchaseOrderContainer/POChange/POChangeForm'));
const POPayment = React.lazy(() => import('./PurchaseOrderContainer/POPayment'));
const VendorPrintMode = React.lazy(() => import('./PurchaseOrderContainer/PO/VendorPrintMode'));
const SelectNonPOPaymentType = React.lazy(
  () => import('./NonPOPaymentContainer/SelectNonPOPaymentType')
);
const AuthorizationForPayment = React.lazy(
  () => import('./NonPOPaymentContainer/AuthorizationForPayment')
);
const NonEmployeeExpensePayment = React.lazy(
  () => import('./NonPOPaymentContainer/NonEmployeeExpensePayment')
);
const PersonalAutomobileMileageVoucher = React.lazy(
  () => import('./NonPOPaymentContainer/PersonalAutomobileMileageVoucher')
);
const PettyCashSummarySheet = React.lazy(
  () => import('./NonPOPaymentContainer/PettyCashSummarySheet')
);
const ReimbursementForCashPurchases = React.lazy(
  () => import('./NonPOPaymentContainer/ReimbursementForCash')
);
const MultiIntraStateTravel = React.lazy(
  () => import('./NonPOPaymentContainer/MultiIntraStateTravel')
);

const TravelRequest = React.lazy(() => import('./TravelContainer/TravelRequest'));
const TravelCompletionWithoutTravelRequest = React.lazy(
  () => import('./TravelContainer/TravelCompletionWithoutTravelRequest')
);
const SubmittedTravel = React.lazy(() => import('./TravelContainer/shared/SubmittedTravel'));
const NonPOListing = React.lazy(() => import('./NonPOListing'));
const SubmittedNonPO = React.lazy(() => import('./NonPOPaymentContainer/shared/SubmittedNonPO'));
const TravelListing = React.lazy(() => import('./TravelListing'));
const UHReportContainer = React.lazy(() => import('./UHReportContainer'));
const RCUHReportContainer = React.lazy(() => import('./RCUHReportContainer'));
const FinancialReportContainer = React.lazy(() => import('./FinancialForecastReports'));
const RegisterForEpayments = React.lazy(() => import('./RegisterForEpayments'));
const VacationSickLeave = React.lazy(() => import('./VacationSickLeave'));

const Routing: React.FC<{ location: Location }> = (props) => {
  Navigator.setTopHistory(useHistory());

  useComponentDidMount(() => {
    const currentWebTenant = TenantService.getWebTenant();
    TenantService.setTenant({ name: currentWebTenant });
  });

  return (
    <Box
      sx={{
        overflowX: 'auto',
        height: '100vh',
      }}
      className="layout-container"
    >
      <Navbar />
      <Box
        sx={{
          minWidth: '750px',
        }}
        minHeight={'calc(100vh - 339px)'}
        className="print__no-margin"
      >
        <Suspense fallback={<LoadingContainer />}>
          <Switch location={props.location}>
            <Route path={PATHS.root} render={() => <Redirect to={PATHS.dashboard} />} exact />
            <CustomRoute pageRequiredAuth path={PATHS.dashboard} component={Dashboard} />
            {/* Users */}
            <CustomRoute pageRequiredAuth path={PATHS.addUser} component={CRUUSerContainer} exact />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.userDetail}/:userId`}
              component={CRUUSerContainer}
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.userManagements}
              component={UsersManagement}
            />
            {/* PO */}
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.purchaseOrderDetailNotFound}/:message`}
              component={PurchaseOrder}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.purchaseOrderDetail}/:id`}
              component={PurchaseOrder}
            />

            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.poAdditionalForm}/:formCode`}
              component={AdditionalPOFormsContainer}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.submittedPurchaseOrder}/:id`}
              component={SubmittedPurchaseOrder}
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.createPurchaseOrders}
              component={PurchaseOrder}
            />
            {/* PO Change */}
            <CustomRoute
              pageRequiredAuth
              path={PATHS.poChangeOptions}
              component={SelectChangeFormType}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.poChangeFormNotFound}/:message`}
              component={POChangeForm}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.poChangeForm}/:id`}
              component={POChangeForm}
            />

            {/* PO Payment */}
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.poPaymentFormNotFound}/:message`}
              component={POPayment}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.poPaymentForm}/:id`}
              component={POPayment}
            />

            {/* Purchasing List*/}
            <CustomRoute pageRequiredAuth path={PATHS.purchasingOrders} component={POListing} />
            {/* Non PO Payment */}
            <CustomRoute
              pageRequiredAuth
              path={PATHS.nonPOPaymentOptions}
              component={SelectNonPOPaymentType}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.createAuthorizationForPayment}`}
              component={AuthorizationForPayment}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.authorizationForPaymentDetailNotFound}/:message`}
              component={AuthorizationForPayment}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.authorizationForPaymentDetail}/:id`}
              component={AuthorizationForPayment}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.createNonEmployeeTravelPayment}`}
              component={NonEmployeeExpensePayment}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.nonEmployeeTravelPaymentDetailNotFound}/:message`}
              component={NonEmployeeExpensePayment}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.nonEmployeeTravelPaymentDetail}/:id`}
              component={NonEmployeeExpensePayment}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.createPersonalAutoPayment}`}
              component={PersonalAutomobileMileageVoucher}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.personalAutoPaymentDetailNotFound}/:message`}
              component={PersonalAutomobileMileageVoucher}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.personalAutoPaymentDetail}/:id`}
              component={PersonalAutomobileMileageVoucher}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.createPettyCashPayment}`}
              component={PettyCashSummarySheet}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.pettyCashPaymentDetailNotFound}/:message`}
              component={PettyCashSummarySheet}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.pettyCashPaymentDetail}/:id`}
              component={PettyCashSummarySheet}
            />

            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.submittedNonPOPayment}/:id`}
              component={SubmittedNonPO}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.reimbursementPayment}/:id`}
              component={ReimbursementForCashPurchases}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.multiTravelPayment}/:id`}
              component={MultiIntraStateTravel}
            />
            {/* Travel Completion */}
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.createTravelRequest}`}
              component={TravelRequest}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.createTravelCompletion}`}
              component={TravelCompletionWithoutTravelRequest}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.submittedTravel}/:id`}
              component={SubmittedTravel}
            />
            {/* Travel Completion Listing */}
            <CustomRoute pageRequiredAuth path={PATHS.travelListing} component={TravelListing} />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.travelRequestDetailNotFound}/:message`}
              component={TravelRequest}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.travelRequestDetail}/:id`}
              component={TravelRequest}
            />

            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.travelCompletionDetailNotFound}/:message`}
              component={TravelCompletionWithoutTravelRequest}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.travelCompletionDetail}/:id`}
              component={TravelCompletionWithoutTravelRequest}
            />
            {/* Purchasing List*/}
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.submittedNonPOPayment}/:id`}
              component={SubmittedNonPO}
            />
            {/* Non PO Listing*/}
            <CustomRoute pageRequiredAuth path={PATHS.nonPOListing} component={NonPOListing} />
            {/* Vendor Print Mode */}
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.vendorPrintMode}/:id`}
              component={VendorPrintMode}
            />
            {/* Vendors */}
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.addVendorRegistration}`}
              component={VendorRegistration}
            />
            {/* Vendor Registration Detail */}
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.vendorRegistrationDetail}/:vendorCode`}
              component={VendorRegistration}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.editVendorMaster}/:vendorCode`}
              component={EditVendorsMaster}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.addVendorMaster}`}
              component={EditVendorsMaster}
            />
            <CustomRoute pageRequiredAuth path={PATHS.vendors} component={VendorsManagement} />
            {/* Profile */}
            <CustomRoute pageRequiredAuth path={PATHS.switchUser} component={SwitchUser} />
            <CustomRoute pageRequiredAuth path={PATHS.myProfile} component={EditProfile} />
            {/* Global Settings */}
            <CustomRoute pageRequiredAuth path={PATHS.globalSettings} component={GlobalSettings} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.expiredPassword}
              component={ChangePasswordExpiredEmptyScreen}
            />
            {/* Staff Listing */}
            <CustomRoute pageRequiredAuth path={PATHS.rcuhStaffListing} component={StaffListing} />
            {/* Check Printing */}
            <CustomRoute pageRequiredAuth path={PATHS.checkPrinting} component={CheckPrinting} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.checkPrintingSuccess}
              component={CheckPrintingSuccess}
            />
            {/* Check Register */}
            <CustomRoute
              pageRequiredAuth
              path={PATHS.checkRegisterListing}
              component={CheckRegisterListing}
            />
            <CustomRoute
              pageRequiredAuth
              path={`${PATHS.checkRegisterDetail}/:id`}
              component={CheckRegisterDetail}
            />
            {/* UH Report */}
            <CustomRoute
              pageRequiredAuth
              path={PATHS.uhProjectReports}
              component={UHReportContainer}
            />
            {/* RCUH Report */}
            <CustomRoute
              pageRequiredAuth
              path={PATHS.rcuhProjectReports}
              component={RCUHReportContainer}
            />
            {/* Financial Report */}
            <CustomRoute
              pageRequiredAuth
              path={PATHS.financialForecastReports}
              component={FinancialReportContainer}
            />
            {/* Miscellaneous */}
            <CustomRoute
              pageRequiredAuth
              path={PATHS.searchAllDocuments}
              component={MiscellaneousSearchTable}
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.registerForePayments}
              component={RegisterForEpayments}
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.vacationSickLeave}
              component={VacationSickLeave}
            />

            <Route path={PATHS.dev} component={Dev} />
            <CustomRoute path={PATHS.dev} component={Dev} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Box>

      <Footer />

      <AuthContainer />
      <NetworkStatusContainer />
      <ContentContainer />
      <DialogContainer />
      <LightboxContainer />
      <ToastContainer />
      <ScrollToTop />
      <CheckPasswordExpiredContainer />
    </Box>
  );
};

export default Routing;

const CRouting: React.FC<Props> = ({ isAuthenticated, pageRequiredAuth, component, ...rest }) => {
  const renderRoute = (Component: any) => (props: RouteProps) => {
    if (isAuthenticated === null) return <SplashScreen />;

    if ((isAuthenticated && pageRequiredAuth) || (!isAuthenticated && !pageRequiredAuth)) {
      // Before render component, check permission first
      return (
        <CustomErrorBoundary showErrorMessage>
          <Component {...props} />
        </CustomErrorBoundary>
      );
    }

    const redirectPath = isAuthenticated ? PATHS.dashboard : PATHS.dashboard;
    const redirectProps = {
      to: {
        pathname: redirectPath,
        state: { from: props.location },
      },
    };

    return (
      <CustomErrorBoundary fallback={<LoadingContainer />}>
        <Redirect {...redirectProps} />
      </CustomErrorBoundary>
    );
  };

  return <Route {...rest} render={renderRoute(component)} />;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps & { pageRequiredAuth?: boolean };

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {};

const CustomRoute = connect(mapStateToProps, mapDispatchToProps)(CRouting);
